export const idlFactory = ({ IDL }) => {
  const AppMode = IDL.Variant({
    'Enabled' : IDL.Null,
    'Disabled' : IDL.Null,
    'Readonly' : IDL.Null,
  });
  const AppState = IDL.Record({ 'mode' : AppMode });
  const Error = IDL.Variant({
    'StoreNotFound' : IDL.Record({ 'path' : IDL.Text }),
  });
  const Error_1 = IDL.Variant({
    'LockFailed' : IDL.Null,
    'WasmNotFound' : IDL.Record({ 'path' : IDL.Text }),
  });
  const Response = IDL.Variant({
    'CanisterCreate' : IDL.Principal,
    'CanisterUpgrade' : IDL.Null,
    'Cycles' : IDL.Null,
  });
  const RequestError = IDL.Variant({
    'Wasm' : IDL.Record({ 'source' : Error_1 }),
    'InvalidResponse' : IDL.Record({ 'response' : Response }),
  });
  const Error_2 = IDL.Variant({
    'NotInitialized' : IDL.Null,
    'AlreadyInitialized' : IDL.Null,
    'Mutex' : IDL.Record({ 'msg' : IDL.Text }),
    'SerdeJson' : IDL.Record({ 'msg' : IDL.Text }),
  });
  const UserIndexError = IDL.Variant({
    'Schema' : IDL.Record({ 'source' : Error_2 }),
    'UserExists' : IDL.Record({ 'id' : IDL.Principal }),
    'RoleNotFound' : IDL.Record({ 'role' : IDL.Text }),
    'UserHasRole' : IDL.Record({ 'role' : IDL.Text }),
    'UserNotFound' : IDL.Record({ 'id' : IDL.Principal }),
    'UserDoesNotHaveRole' : IDL.Record({ 'role' : IDL.Text }),
  });
  const SubnetIndexError = IDL.Variant({
    'CanisterTypeNotFound' : IDL.Record({ 'path' : IDL.Text }),
  });
  const CellError = IDL.Variant({
    'ValueTooLarge' : IDL.Record({ 'size' : IDL.Nat64 }),
    'Init' : IDL.Record({ 'error' : IDL.Text }),
  });
  const Error_3 = IDL.Variant({
    'Cell' : IDL.Record({ 'source' : CellError }),
  });
  const CanisterStateError = IDL.Variant({
    'Ic' : IDL.Record({ 'source' : Error_3 }),
    'PathNotSet' : IDL.Null,
    'RootIdNotSet' : IDL.Null,
  });
  const ChildIndexError = IDL.Variant({
    'CanisterNotFound' : IDL.Record({ 'id' : IDL.Principal }),
  });
  const AppStateError = IDL.Variant({
    'Ic' : IDL.Record({ 'source' : Error_3 }),
    'AlreadyInMode' : AppState,
  });
  const Error_4 = IDL.Variant({
    'UserIndex' : IDL.Record({ 'source' : UserIndexError }),
    'SubnetIndex' : IDL.Record({ 'source' : SubnetIndexError }),
    'CanisterState' : IDL.Record({ 'source' : CanisterStateError }),
    'ChildIndex' : IDL.Record({ 'source' : ChildIndexError }),
    'AppState' : IDL.Record({ 'source' : AppStateError }),
  });
  const AuthError = IDL.Variant({
    'NotParent' : IDL.Record({ 'id' : IDL.Principal }),
    'NotRoot' : IDL.Record({ 'id' : IDL.Principal }),
    'NotThis' : IDL.Record({ 'id' : IDL.Principal }),
    'NotController' : IDL.Record({ 'id' : IDL.Principal }),
    'NotAllowed' : IDL.Null,
    'NotCanisterPath' : IDL.Record({ 'id' : IDL.Principal, 'path' : IDL.Text }),
    'NotPermitted' : IDL.Record({
      'id' : IDL.Principal,
      'permission' : IDL.Text,
    }),
    'NoUserCanister' : IDL.Null,
    'NotChild' : IDL.Record({ 'id' : IDL.Principal }),
    'RoleNotFound' : IDL.Record({ 'role' : IDL.Text }),
    'NotSubnet' : IDL.Record({ 'id' : IDL.Principal }),
    'NoRulesDefined' : IDL.Null,
    'CoreState' : IDL.Record({ 'source' : Error_4 }),
  });
  const Error_5 = IDL.Variant({
    'Deserialize' : IDL.Record({ 'msg' : IDL.Text }),
    'Serialize' : IDL.Record({ 'msg' : IDL.Text }),
  });
  const Error_6 = IDL.Variant({
    'ParseField' : IDL.Record({ 'field' : IDL.Text }),
    'Cbor' : IDL.Record({ 'source' : Error_5 }),
    'InvalidEnumHash' : IDL.Record({ 'key' : IDL.Nat64 }),
    'Validation' : IDL.Record({
      'errors' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Vec(IDL.Text))),
    }),
  });
  const IterError = IDL.Variant({ 'NoResultsFound' : IDL.Null });
  const LoadError = IDL.Variant({
    'KeyNotFound' : IDL.Record({
      'key' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Vec(IDL.Text))),
    }),
    'NoResultsFound' : IDL.Null,
    'FilterNotAllowed' : IDL.Null,
    'RangeNotAllowed' : IDL.Null,
  });
  const SaveError = IDL.Variant({
    'KeyNotFound' : IDL.Record({
      'key' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Vec(IDL.Text))),
    }),
    'NoResultsFound' : IDL.Null,
    'KeyExists' : IDL.Record({
      'key' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Vec(IDL.Text))),
    }),
  });
  const ResolverError = IDL.Variant({
    'EntityNotFound' : IDL.Record({ 'path' : IDL.Text }),
    'Schema' : IDL.Record({ 'source' : Error_2 }),
  });
  const Error_7 = IDL.Variant({
    'Db' : IDL.Record({ 'source' : Error }),
    'Orm' : IDL.Record({ 'source' : Error_6 }),
    'Iter' : IDL.Record({ 'source' : IterError }),
    'Load' : IDL.Record({ 'source' : LoadError }),
    'Save' : IDL.Record({ 'source' : SaveError }),
    'Resolver' : IDL.Record({ 'source' : ResolverError }),
  });
  const CrudError = IDL.Variant({
    'Orm' : IDL.Record({ 'source' : Error_6 }),
    'EntityNotFound' : IDL.Record({ 'path' : IDL.Text }),
    'Query' : IDL.Record({ 'source' : Error_7 }),
  });
  const UpgradeError = IDL.Variant({ 'WasmHashMatches' : IDL.Null });
  const SchemaError = IDL.Variant({
    'CanisterNotFound' : IDL.Record({ 'path' : IDL.Text }),
    'Schema' : IDL.Record({ 'source' : Error_2 }),
  });
  const CanisterError = IDL.Variant({
    'State' : IDL.Record({ 'source' : Error_4 }),
  });
  const Error_8 = IDL.Variant({
    'CannotParseToml' : IDL.Record({ 'msg' : IDL.Text }),
    'NotInitialized' : IDL.Null,
    'AlreadyInitialized' : IDL.Null,
    'Mutex' : IDL.Record({ 'msg' : IDL.Text }),
  });
  const CreateError = IDL.Variant({
    'Config' : IDL.Record({ 'source' : Error_8 }),
  });
  const SubnetError = IDL.Variant({
    'NoUserCanister' : IDL.Null,
    'CoreState' : IDL.Record({ 'source' : Error_4 }),
  });
  const Error_9 = IDL.Variant({
    'Request' : IDL.Record({ 'source' : RequestError }),
    'Auth' : IDL.Record({ 'source' : AuthError }),
    'Call' : IDL.Record({ 'msg' : IDL.Text }),
    'Crud' : IDL.Record({ 'source' : CrudError }),
    'Upgrade' : IDL.Record({ 'source' : UpgradeError }),
    'Schema' : IDL.Record({ 'source' : SchemaError }),
    'Canister' : IDL.Record({ 'source' : CanisterError }),
    'Create' : IDL.Record({ 'source' : CreateError }),
    'Subnet' : IDL.Record({ 'source' : SubnetError }),
  });
  const Error_10 = IDL.Variant({
    'Db' : IDL.Record({ 'source' : Error }),
    'Api' : IDL.Record({ 'source' : Error_9 }),
    'CoreSchema' : IDL.Record({ 'source' : Error_2 }),
    'Query' : IDL.Record({ 'source' : Error_7 }),
    'CoreWasm' : IDL.Record({ 'source' : Error_1 }),
    'Config' : IDL.Record({ 'source' : Error_8 }),
    'CoreState' : IDL.Record({ 'source' : Error_4 }),
  });
  const Result = IDL.Variant({ 'Ok' : IDL.Null, 'Err' : Error_10 });
  const FromUserRecord = IDL.Record({ 'user_id' : IDL.Principal });
  const FromCanisterRecord = IDL.Record({
    'canister_version' : IDL.Opt(IDL.Nat64),
    'canister_id' : IDL.Principal,
  });
  const CanisterChangeOrigin = IDL.Variant({
    'from_user' : FromUserRecord,
    'from_canister' : FromCanisterRecord,
  });
  const CreationRecord = IDL.Record({ 'controllers' : IDL.Vec(IDL.Principal) });
  const CodeDeploymentMode = IDL.Variant({
    'reinstall' : IDL.Null,
    'upgrade' : IDL.Null,
    'install' : IDL.Null,
  });
  const CodeDeploymentRecord = IDL.Record({
    'mode' : CodeDeploymentMode,
    'module_hash' : IDL.Vec(IDL.Nat8),
  });
  const LoadSnapshotRecord = IDL.Record({
    'canister_version' : IDL.Nat64,
    'taken_at_timestamp' : IDL.Nat64,
    'snapshot_id' : IDL.Vec(IDL.Nat8),
  });
  const CanisterChangeDetails = IDL.Variant({
    'creation' : CreationRecord,
    'code_deployment' : CodeDeploymentRecord,
    'load_snapshot' : LoadSnapshotRecord,
    'controllers_change' : CreationRecord,
    'code_uninstall' : IDL.Null,
  });
  const CanisterChange = IDL.Record({
    'timestamp_nanos' : IDL.Nat64,
    'canister_version' : IDL.Nat64,
    'origin' : CanisterChangeOrigin,
    'details' : CanisterChangeDetails,
  });
  const CanisterInfoResponse = IDL.Record({
    'controllers' : IDL.Vec(IDL.Principal),
    'module_hash' : IDL.Opt(IDL.Vec(IDL.Nat8)),
    'recent_changes' : IDL.Vec(CanisterChange),
    'total_num_changes' : IDL.Nat64,
  });
  const Result_1 = IDL.Variant({ 'Ok' : IDL.Text, 'Err' : Error_10 });
  const CanisterState = IDL.Record({
    'root_id' : IDL.Opt(IDL.Principal),
    'path' : IDL.Opt(IDL.Text),
    'parent_id' : IDL.Opt(IDL.Principal),
  });
  const DeleteRequest = IDL.Record({
    'key' : IDL.Vec(IDL.Text),
    'entity' : IDL.Text,
  });
  const DeleteResponse = IDL.Record({
    'keys' : IDL.Vec(IDL.Vec(IDL.Tuple(IDL.Text, IDL.Vec(IDL.Text)))),
  });
  const Result_2 = IDL.Variant({ 'Ok' : DeleteResponse, 'Err' : Error_10 });
  const LoadMethod = IDL.Variant({
    'All' : IDL.Null,
    'One' : IDL.Vec(IDL.Text),
    'Many' : IDL.Vec(IDL.Vec(IDL.Text)),
    'Range' : IDL.Tuple(IDL.Vec(IDL.Text), IDL.Vec(IDL.Text)),
    'Prefix' : IDL.Vec(IDL.Text),
  });
  const SortDirection = IDL.Variant({ 'Asc' : IDL.Null, 'Desc' : IDL.Null });
  const Filter = IDL.Variant({
    'All' : IDL.Text,
    'Fields' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
  });
  const LoadFormat = IDL.Variant({ 'Rows' : IDL.Null, 'Count' : IDL.Null });
  const LoadRequest = IDL.Record({
    'entity' : IDL.Text,
    'method' : LoadMethod,
    'order' : IDL.Opt(IDL.Vec(IDL.Tuple(IDL.Text, SortDirection))),
    'offset' : IDL.Nat32,
    'limit' : IDL.Opt(IDL.Nat32),
    'filter' : IDL.Opt(Filter),
    'format' : LoadFormat,
  });
  const Metadata = IDL.Record({
    'created' : IDL.Nat64,
    'modified' : IDL.Nat64,
  });
  const QueryValue = IDL.Record({
    'metadata' : Metadata,
    'data' : IDL.Vec(IDL.Nat8),
  });
  const QueryRow = IDL.Record({
    'key' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Vec(IDL.Text))),
    'value' : QueryValue,
  });
  const LoadResponse = IDL.Variant({
    'Rows' : IDL.Vec(QueryRow),
    'Count' : IDL.Nat32,
  });
  const Result_3 = IDL.Variant({ 'Ok' : LoadResponse, 'Err' : Error_10 });
  const SaveRequestAction = IDL.Variant({
    'Create' : IDL.Null,
    'Update' : IDL.Null,
  });
  const SaveRequest = IDL.Record({
    'entity' : IDL.Text,
    'action' : SaveRequestAction,
    'data' : IDL.Vec(IDL.Nat8),
  });
  const CreateResponse = IDL.Record({ 'row' : QueryRow });
  const SaveResponse = IDL.Variant({
    'Create' : CreateResponse,
    'Update' : CreateResponse,
  });
  const Result_4 = IDL.Variant({ 'Ok' : SaveResponse, 'Err' : Error_10 });
  const Status = IDL.Variant({ 'Active' : IDL.Null, 'Archived' : IDL.Null });
  const Part = IDL.Record({
    'mesh_id' : IDL.Opt(IDL.Text),
    'modifier_ids' : IDL.Vec(IDL.Text),
    'part_id' : IDL.Text,
  });
  const Appearance = IDL.Record({
    'mob_model_id' : IDL.Text,
    'parts' : IDL.Vec(Part),
  });
  const ActionSlot = IDL.Variant({
    'Spell' : IDL.Text,
    'Item' : IDL.Text,
    'Ability' : IDL.Text,
  });
  const Layout = IDL.Record({
    'action_bars' : IDL.Vec(
      IDL.Tuple(IDL.Nat8, IDL.Vec(IDL.Tuple(IDL.Nat8, ActionSlot)))
    ),
  });
  const Settings = IDL.Record({ 'something' : IDL.Bool });
  const Gender = IDL.Variant({ 'Male' : IDL.Null, 'Female' : IDL.Null });
  const Position = IDL.Record({
    'x' : IDL.Int32,
    'y' : IDL.Int32,
    'z' : IDL.Int32,
  });
  const State = IDL.Record({ 'todo' : IDL.Bool });
  const Object = IDL.Record({
    'id' : IDL.Text,
    'state' : State,
    'ability_id' : IDL.Text,
  });
  const Skill = IDL.Variant({
    'Gardening' : IDL.Null,
    'Survival' : IDL.Null,
    'Cooking' : IDL.Null,
    'Unspecified' : IDL.Null,
  });
  const Object_1 = IDL.Record({
    'id' : IDL.Text,
    'skill' : Skill,
    'state' : State,
  });
  const Object_2 = IDL.Record({
    'id' : IDL.Text,
    'role_id' : IDL.Text,
    'state' : State,
  });
  const Character = IDL.Record({
    'id' : IDL.Text,
    'player_id' : IDL.Text,
    'status' : Status,
    'appearance' : Appearance,
    'name' : IDL.Text,
    'layout' : Layout,
    'settings' : Settings,
    'tokens' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Nat16)),
    'currency' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Nat32)),
    'gender' : Gender,
    'species_id' : IDL.Text,
    'position' : Position,
    'abilities' : IDL.Vec(IDL.Tuple(IDL.Text, Object)),
    'skills' : IDL.Vec(IDL.Tuple(Skill, Object_1)),
    'roles' : IDL.Vec(IDL.Tuple(IDL.Text, Object_2)),
  });
  const Error_11 = IDL.Variant({
    'Mimic' : IDL.Record({ 'source' : Error_10 }),
    'Query' : IDL.Record({ 'source' : Error_7 }),
  });
  const ActionError = IDL.Variant({
    'Orm' : IDL.Record({ 'source' : Error_6 }),
    'NoActiveCharacter' : IDL.Null,
    'PlayerMismatch' : IDL.Record({ 'id' : IDL.Text }),
    'PetNotFound' : IDL.Record({ 'id' : IDL.Text }),
    'Action' : IDL.Record({ 'action' : IDL.Text }),
    'PlayerNotFound' : IDL.Record({ 'id' : IDL.Text }),
    'CharacterMismatch' : IDL.Record({ 'id' : IDL.Text }),
    'Query' : IDL.Record({ 'source' : Error_7 }),
  });
  const ManifestError = IDL.Variant({
    'Query' : IDL.Record({ 'source' : Error_7 }),
  });
  const ZoneServiceError = IDL.Variant({
    'NotInitialized' : IDL.Null,
    'AlreadyInitialized' : IDL.Null,
  });
  const Error_12 = IDL.Variant({
    'Zone' : IDL.Record({ 'source' : ZoneServiceError }),
    'Query' : IDL.Record({ 'source' : Error_7 }),
  });
  const Error_13 = IDL.Variant({
    'Repo' : IDL.Record({ 'source' : Error_11 }),
    'Action' : IDL.Record({ 'source' : ActionError }),
    'Mimic' : IDL.Record({ 'source' : Error_10 }),
    'Manifest' : IDL.Record({ 'source' : ManifestError }),
    'Service' : IDL.Record({ 'source' : Error_12 }),
    'Query' : IDL.Record({ 'source' : Error_7 }),
  });
  const Result_5 = IDL.Variant({ 'Ok' : Character, 'Err' : Error_13 });
  const Resolution = IDL.Variant({ 'R1920X1080' : IDL.Null });
  const DisplayMode = IDL.Variant({
    'Windowed' : IDL.Null,
    'Fullscreen' : IDL.Null,
  });
  const Graphics = IDL.Record({
    'contrast' : IDL.Nat8,
    'brightness' : IDL.Nat8,
    'resolution' : Resolution,
    'display_mode' : DisplayMode,
  });
  const Controls = IDL.Record({
    'mouse_invert' : IDL.Bool,
    'mouse_speed' : IDL.Nat8,
    'inventory_mouse_controls' : IDL.Bool,
    'keyboard' : IDL.Vec(
      IDL.Tuple(IDL.Text, IDL.Tuple(IDL.Opt(IDL.Text), IDL.Opt(IDL.Text)))
    ),
  });
  const Preferences = IDL.Record({ 'language_id' : IDL.Opt(IDL.Text) });
  const Sound = IDL.Record({
    'sfx_volume' : IDL.Nat8,
    'music_disabled' : IDL.Bool,
    'music_volume' : IDL.Nat8,
    'sfx_disabled' : IDL.Bool,
  });
  const Gameplay = IDL.Record({
    'show_nameplates' : IDL.Bool,
    'autoloot' : IDL.Bool,
    'show_tooltips' : IDL.Bool,
  });
  const Settings_1 = IDL.Record({
    'graphics' : Graphics,
    'controls' : Controls,
    'preferences' : Preferences,
    'sound' : Sound,
    'gameplay' : Gameplay,
  });
  const Networks = IDL.Record({
    'catalyze' : IDL.Opt(IDL.Text),
    'openchat' : IDL.Opt(IDL.Text),
  });
  const Profile = IDL.Record({
    'username' : IDL.Opt(IDL.Text),
    'networks' : IDL.Opt(Networks),
    'display_name' : IDL.Opt(IDL.Text),
  });
  const Player = IDL.Record({
    'id' : IDL.Text,
    'settings' : Settings_1,
    'active_character_id' : IDL.Opt(IDL.Text),
    'principal_' : IDL.Principal,
    'profile' : IDL.Opt(Profile),
  });
  const Result_6 = IDL.Variant({ 'Ok' : Player, 'Err' : Error_13 });
  const Set = IDL.Record({ 'biome_id' : IDL.Opt(IDL.Text) });
  const Zone = IDL.Record({
    'id' : IDL.Text,
    'name' : IDL.Text,
    'description' : IDL.Text,
    'lower' : Position,
    'upper' : Position,
    'config' : Set,
  });
  const Result_7 = IDL.Variant({ 'Ok' : IDL.Vec(Zone), 'Err' : Error_13 });
  const Result_8 = IDL.Variant({ 'Ok' : IDL.Null, 'Err' : Error_13 });
  const Result_9 = IDL.Variant({ 'Ok' : IDL.Text, 'Err' : Error_13 });
  const Result_10 = IDL.Variant({
    'Ok' : IDL.Vec(Character),
    'Err' : Error_13,
  });
  const Result_11 = IDL.Variant({ 'Ok' : IDL.Vec(Player), 'Err' : Error_13 });
  const Feed = IDL.Record({ 'item_id' : IDL.Text });
  const Rename = IDL.Record({ 'old_name' : IDL.Text, 'new_name' : IDL.Text });
  const Kind = IDL.Variant({
    'Feed' : Feed,
    'Test' : IDL.Null,
    'Rename' : Rename,
  });
  const Action = IDL.Record({
    'pet_id' : IDL.Text,
    'kind' : Kind,
    'position' : Position,
  });
  const UpdateProfile = IDL.Record({ 'profile' : Profile });
  const CreateCharacter = IDL.Record({
    'appearance' : Appearance,
    'name' : IDL.Text,
    'gender' : Gender,
    'species_id' : IDL.Text,
  });
  const UpdateSettings = IDL.Record({
    'graphics' : IDL.Opt(Graphics),
    'controls' : IDL.Opt(Controls),
    'preferences' : IDL.Opt(Preferences),
    'sound' : IDL.Opt(Sound),
    'gameplay' : IDL.Opt(Gameplay),
  });
  const SetActiveCharacter = IDL.Record({ 'character_id' : IDL.Text });
  const Kind_1 = IDL.Variant({
    'UpdateProfile' : UpdateProfile,
    'Test' : IDL.Null,
    'CreateCharacter' : CreateCharacter,
    'UpdateSettings' : UpdateSettings,
    'SetActiveCharacter' : SetActiveCharacter,
  });
  const Action_1 = IDL.Record({ 'kind' : Kind_1 });
  const CreatePet = IDL.Record({
    'name' : IDL.Text,
    'gender' : Gender,
    'species_id' : IDL.Text,
  });
  const Kind_2 = IDL.Variant({ 'Test' : IDL.Null, 'CreatePet' : CreatePet });
  const Action_2 = IDL.Record({ 'kind' : Kind_2, 'position' : Position });
  const Kind_3 = IDL.Variant({
    'Pet' : Action,
    'Player' : Action_1,
    'Character' : Action_2,
  });
  const Action_3 = IDL.Record({ 'id' : IDL.Text, 'kind' : Kind_3 });
  const Request = IDL.Record({
    'player_id' : IDL.Text,
    'character_id' : IDL.Opt(IDL.Text),
    'actions' : IDL.Vec(Action_3),
  });
  const Kind_4 = IDL.Variant({ 'Empty' : IDL.Null });
  const Result_12 = IDL.Variant({ 'Ok' : Kind_4, 'Err' : IDL.Text });
  const Kind_5 = IDL.Variant({
    'Empty' : IDL.Null,
    'CreateCharacter' : SetActiveCharacter,
  });
  const Result_13 = IDL.Variant({ 'Ok' : Kind_5, 'Err' : IDL.Text });
  const CreatePet_1 = IDL.Record({ 'pet_id' : IDL.Text });
  const Kind_6 = IDL.Variant({ 'Empty' : IDL.Null, 'CreatePet' : CreatePet_1 });
  const Result_14 = IDL.Variant({ 'Ok' : Kind_6, 'Err' : IDL.Text });
  const Kind_7 = IDL.Variant({
    'Pet' : Result_12,
    'Player' : Result_13,
    'Character' : Result_14,
  });
  const Action_4 = IDL.Record({ 'id' : IDL.Text, 'kind' : Kind_7 });
  const Response_1 = IDL.Record({
    'player_id' : IDL.Text,
    'character_id' : IDL.Opt(IDL.Text),
    'actions' : IDL.Vec(Action_4),
  });
  const Result_15 = IDL.Variant({ 'Ok' : Response_1, 'Err' : Error_13 });
  const Result_16 = IDL.Variant({ 'Ok' : IDL.Vec(IDL.Text), 'Err' : Error_10 });
  return IDL.Service({
    'app_state' : IDL.Func([], [AppState], ['query']),
    'app_state_cascade' : IDL.Func([AppState], [Result], []),
    'canister_balance' : IDL.Func([], [IDL.Nat], ['query']),
    'canister_caller' : IDL.Func([], [IDL.Principal], ['query']),
    'canister_id' : IDL.Func([], [IDL.Principal], ['query']),
    'canister_info' : IDL.Func([], [CanisterInfoResponse], []),
    'canister_path' : IDL.Func([], [Result_1], ['query']),
    'canister_state' : IDL.Func([], [CanisterState], ['query']),
    'canister_time' : IDL.Func([], [IDL.Nat64], ['query']),
    'canister_upgrade_children' : IDL.Func(
        [IDL.Opt(IDL.Principal)],
        [Result],
        [],
      ),
    'canister_version' : IDL.Func([], [IDL.Nat64], ['query']),
    'child_index' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Text))],
        ['query'],
      ),
    'crud_delete' : IDL.Func([DeleteRequest], [Result_2], []),
    'crud_load' : IDL.Func([LoadRequest], [Result_3], ['composite_query']),
    'crud_save' : IDL.Func([SaveRequest], [Result_4], []),
    'fixtures_replace_all' : IDL.Func([], [Result], []),
    'get_character' : IDL.Func([IDL.Text, IDL.Text], [Result_5], ['query']),
    'get_player' : IDL.Func([IDL.Text], [Result_6], ['query']),
    'get_zones' : IDL.Func(
        [IDL.Text, IDL.Text],
        [Result_7],
        ['composite_query'],
      ),
    'ic_cycles_accept' : IDL.Func([IDL.Nat64], [IDL.Nat64], []),
    'init_async' : IDL.Func([], [Result_8], []),
    'init_user' : IDL.Func([IDL.Principal], [Result_9], []),
    'list_characters' : IDL.Func([IDL.Text], [Result_10], ['composite_query']),
    'list_players' : IDL.Func([], [Result_11], ['composite_query']),
    'process_actions' : IDL.Func([Request], [Result_15], []),
    'store_keys' : IDL.Func([IDL.Text], [Result_16], ['composite_query']),
    'subnet_index' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(IDL.Text, IDL.Principal))],
        ['query'],
      ),
    'subnet_index_cascade' : IDL.Func(
        [IDL.Vec(IDL.Tuple(IDL.Text, IDL.Principal))],
        [Result],
        [],
      ),
  });
};
export const init = ({ IDL }) => { return [IDL.Principal, IDL.Principal]; };
