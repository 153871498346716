export const idlFactory = ({ IDL }) => {
  const AppMode = IDL.Variant({
    'Enabled' : IDL.Null,
    'Disabled' : IDL.Null,
    'Readonly' : IDL.Null,
  });
  const AppState = IDL.Record({ 'mode' : AppMode });
  const Error = IDL.Variant({
    'StoreNotFound' : IDL.Record({ 'path' : IDL.Text }),
  });
  const Error_1 = IDL.Variant({
    'LockFailed' : IDL.Null,
    'WasmNotFound' : IDL.Record({ 'path' : IDL.Text }),
  });
  const Response = IDL.Variant({
    'CanisterCreate' : IDL.Principal,
    'CanisterUpgrade' : IDL.Null,
    'Cycles' : IDL.Null,
  });
  const RequestError = IDL.Variant({
    'Wasm' : IDL.Record({ 'source' : Error_1 }),
    'InvalidResponse' : IDL.Record({ 'response' : Response }),
  });
  const Error_2 = IDL.Variant({
    'NotInitialized' : IDL.Null,
    'AlreadyInitialized' : IDL.Null,
    'Mutex' : IDL.Record({ 'msg' : IDL.Text }),
    'SerdeJson' : IDL.Record({ 'msg' : IDL.Text }),
  });
  const UserIndexError = IDL.Variant({
    'Schema' : IDL.Record({ 'source' : Error_2 }),
    'UserExists' : IDL.Record({ 'id' : IDL.Principal }),
    'RoleNotFound' : IDL.Record({ 'role' : IDL.Text }),
    'UserHasRole' : IDL.Record({ 'role' : IDL.Text }),
    'UserNotFound' : IDL.Record({ 'id' : IDL.Principal }),
    'UserDoesNotHaveRole' : IDL.Record({ 'role' : IDL.Text }),
  });
  const SubnetIndexError = IDL.Variant({
    'CanisterTypeNotFound' : IDL.Record({ 'path' : IDL.Text }),
  });
  const CellError = IDL.Variant({
    'ValueTooLarge' : IDL.Record({ 'size' : IDL.Nat64 }),
    'Init' : IDL.Record({ 'error' : IDL.Text }),
  });
  const Error_3 = IDL.Variant({
    'Cell' : IDL.Record({ 'source' : CellError }),
  });
  const CanisterStateError = IDL.Variant({
    'Ic' : IDL.Record({ 'source' : Error_3 }),
    'PathNotSet' : IDL.Null,
    'RootIdNotSet' : IDL.Null,
  });
  const ChildIndexError = IDL.Variant({
    'CanisterNotFound' : IDL.Record({ 'id' : IDL.Principal }),
  });
  const AppStateError = IDL.Variant({
    'Ic' : IDL.Record({ 'source' : Error_3 }),
    'AlreadyInMode' : AppState,
  });
  const Error_4 = IDL.Variant({
    'UserIndex' : IDL.Record({ 'source' : UserIndexError }),
    'SubnetIndex' : IDL.Record({ 'source' : SubnetIndexError }),
    'CanisterState' : IDL.Record({ 'source' : CanisterStateError }),
    'ChildIndex' : IDL.Record({ 'source' : ChildIndexError }),
    'AppState' : IDL.Record({ 'source' : AppStateError }),
  });
  const AuthError = IDL.Variant({
    'NotParent' : IDL.Record({ 'id' : IDL.Principal }),
    'NotRoot' : IDL.Record({ 'id' : IDL.Principal }),
    'NotThis' : IDL.Record({ 'id' : IDL.Principal }),
    'NotController' : IDL.Record({ 'id' : IDL.Principal }),
    'NotAllowed' : IDL.Null,
    'NotCanisterPath' : IDL.Record({ 'id' : IDL.Principal, 'path' : IDL.Text }),
    'NotPermitted' : IDL.Record({
      'id' : IDL.Principal,
      'permission' : IDL.Text,
    }),
    'NoUserCanister' : IDL.Null,
    'NotChild' : IDL.Record({ 'id' : IDL.Principal }),
    'RoleNotFound' : IDL.Record({ 'role' : IDL.Text }),
    'NotSubnet' : IDL.Record({ 'id' : IDL.Principal }),
    'NoRulesDefined' : IDL.Null,
    'CoreState' : IDL.Record({ 'source' : Error_4 }),
  });
  const Error_5 = IDL.Variant({
    'Deserialize' : IDL.Record({ 'msg' : IDL.Text }),
    'Serialize' : IDL.Record({ 'msg' : IDL.Text }),
  });
  const Error_6 = IDL.Variant({
    'ParseField' : IDL.Record({ 'field' : IDL.Text }),
    'Cbor' : IDL.Record({ 'source' : Error_5 }),
    'InvalidEnumHash' : IDL.Record({ 'key' : IDL.Nat64 }),
    'Validation' : IDL.Record({
      'errors' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Vec(IDL.Text))),
    }),
  });
  const IterError = IDL.Variant({ 'NoResultsFound' : IDL.Null });
  const LoadError = IDL.Variant({
    'KeyNotFound' : IDL.Record({
      'key' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Vec(IDL.Text))),
    }),
    'NoResultsFound' : IDL.Null,
    'FilterNotAllowed' : IDL.Null,
    'RangeNotAllowed' : IDL.Null,
  });
  const SaveError = IDL.Variant({
    'KeyNotFound' : IDL.Record({
      'key' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Vec(IDL.Text))),
    }),
    'NoResultsFound' : IDL.Null,
    'KeyExists' : IDL.Record({
      'key' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Vec(IDL.Text))),
    }),
  });
  const ResolverError = IDL.Variant({
    'EntityNotFound' : IDL.Record({ 'path' : IDL.Text }),
    'Schema' : IDL.Record({ 'source' : Error_2 }),
  });
  const Error_7 = IDL.Variant({
    'Db' : IDL.Record({ 'source' : Error }),
    'Orm' : IDL.Record({ 'source' : Error_6 }),
    'Iter' : IDL.Record({ 'source' : IterError }),
    'Load' : IDL.Record({ 'source' : LoadError }),
    'Save' : IDL.Record({ 'source' : SaveError }),
    'Resolver' : IDL.Record({ 'source' : ResolverError }),
  });
  const CrudError = IDL.Variant({
    'Orm' : IDL.Record({ 'source' : Error_6 }),
    'EntityNotFound' : IDL.Record({ 'path' : IDL.Text }),
    'Query' : IDL.Record({ 'source' : Error_7 }),
  });
  const UpgradeError = IDL.Variant({ 'WasmHashMatches' : IDL.Null });
  const SchemaError = IDL.Variant({
    'CanisterNotFound' : IDL.Record({ 'path' : IDL.Text }),
    'Schema' : IDL.Record({ 'source' : Error_2 }),
  });
  const CanisterError = IDL.Variant({
    'State' : IDL.Record({ 'source' : Error_4 }),
  });
  const Error_8 = IDL.Variant({
    'CannotParseToml' : IDL.Record({ 'msg' : IDL.Text }),
    'NotInitialized' : IDL.Null,
    'AlreadyInitialized' : IDL.Null,
    'Mutex' : IDL.Record({ 'msg' : IDL.Text }),
  });
  const CreateError = IDL.Variant({
    'Config' : IDL.Record({ 'source' : Error_8 }),
  });
  const SubnetError = IDL.Variant({
    'NoUserCanister' : IDL.Null,
    'CoreState' : IDL.Record({ 'source' : Error_4 }),
  });
  const Error_9 = IDL.Variant({
    'Request' : IDL.Record({ 'source' : RequestError }),
    'Auth' : IDL.Record({ 'source' : AuthError }),
    'Call' : IDL.Record({ 'msg' : IDL.Text }),
    'Crud' : IDL.Record({ 'source' : CrudError }),
    'Upgrade' : IDL.Record({ 'source' : UpgradeError }),
    'Schema' : IDL.Record({ 'source' : SchemaError }),
    'Canister' : IDL.Record({ 'source' : CanisterError }),
    'Create' : IDL.Record({ 'source' : CreateError }),
    'Subnet' : IDL.Record({ 'source' : SubnetError }),
  });
  const Error_10 = IDL.Variant({
    'Db' : IDL.Record({ 'source' : Error }),
    'Api' : IDL.Record({ 'source' : Error_9 }),
    'CoreSchema' : IDL.Record({ 'source' : Error_2 }),
    'Query' : IDL.Record({ 'source' : Error_7 }),
    'CoreWasm' : IDL.Record({ 'source' : Error_1 }),
    'Config' : IDL.Record({ 'source' : Error_8 }),
    'CoreState' : IDL.Record({ 'source' : Error_4 }),
  });
  const Result = IDL.Variant({ 'Ok' : IDL.Null, 'Err' : Error_10 });
  const FromUserRecord = IDL.Record({ 'user_id' : IDL.Principal });
  const FromCanisterRecord = IDL.Record({
    'canister_version' : IDL.Opt(IDL.Nat64),
    'canister_id' : IDL.Principal,
  });
  const CanisterChangeOrigin = IDL.Variant({
    'from_user' : FromUserRecord,
    'from_canister' : FromCanisterRecord,
  });
  const CreationRecord = IDL.Record({ 'controllers' : IDL.Vec(IDL.Principal) });
  const CodeDeploymentMode = IDL.Variant({
    'reinstall' : IDL.Null,
    'upgrade' : IDL.Null,
    'install' : IDL.Null,
  });
  const CodeDeploymentRecord = IDL.Record({
    'mode' : CodeDeploymentMode,
    'module_hash' : IDL.Vec(IDL.Nat8),
  });
  const LoadSnapshotRecord = IDL.Record({
    'canister_version' : IDL.Nat64,
    'taken_at_timestamp' : IDL.Nat64,
    'snapshot_id' : IDL.Vec(IDL.Nat8),
  });
  const CanisterChangeDetails = IDL.Variant({
    'creation' : CreationRecord,
    'code_deployment' : CodeDeploymentRecord,
    'load_snapshot' : LoadSnapshotRecord,
    'controllers_change' : CreationRecord,
    'code_uninstall' : IDL.Null,
  });
  const CanisterChange = IDL.Record({
    'timestamp_nanos' : IDL.Nat64,
    'canister_version' : IDL.Nat64,
    'origin' : CanisterChangeOrigin,
    'details' : CanisterChangeDetails,
  });
  const CanisterInfoResponse = IDL.Record({
    'controllers' : IDL.Vec(IDL.Principal),
    'module_hash' : IDL.Opt(IDL.Vec(IDL.Nat8)),
    'recent_changes' : IDL.Vec(CanisterChange),
    'total_num_changes' : IDL.Nat64,
  });
  const Result_1 = IDL.Variant({ 'Ok' : IDL.Text, 'Err' : Error_10 });
  const CanisterState = IDL.Record({
    'root_id' : IDL.Opt(IDL.Principal),
    'path' : IDL.Opt(IDL.Text),
    'parent_id' : IDL.Opt(IDL.Principal),
  });
  const Ic = IDL.Record({
    'controllers' : IDL.Vec(IDL.Principal),
    'admins' : IDL.Vec(IDL.Principal),
  });
  const Config = IDL.Record({ 'ic' : Ic, 'orm' : IDL.Record({}) });
  const Error_11 = IDL.Variant({
    'Mimic' : IDL.Record({ 'source' : Error_10 }),
    'Query' : IDL.Record({ 'source' : Error_7 }),
  });
  const ActionError = IDL.Variant({
    'Orm' : IDL.Record({ 'source' : Error_6 }),
    'NoActiveCharacter' : IDL.Null,
    'PlayerMismatch' : IDL.Record({ 'id' : IDL.Text }),
    'PetNotFound' : IDL.Record({ 'id' : IDL.Text }),
    'Action' : IDL.Record({ 'action' : IDL.Text }),
    'PlayerNotFound' : IDL.Record({ 'id' : IDL.Text }),
    'CharacterMismatch' : IDL.Record({ 'id' : IDL.Text }),
    'Query' : IDL.Record({ 'source' : Error_7 }),
  });
  const ManifestError = IDL.Variant({
    'Query' : IDL.Record({ 'source' : Error_7 }),
  });
  const ZoneServiceError = IDL.Variant({
    'NotInitialized' : IDL.Null,
    'AlreadyInitialized' : IDL.Null,
  });
  const Error_12 = IDL.Variant({
    'Zone' : IDL.Record({ 'source' : ZoneServiceError }),
    'Query' : IDL.Record({ 'source' : Error_7 }),
  });
  const Error_13 = IDL.Variant({
    'Repo' : IDL.Record({ 'source' : Error_11 }),
    'Action' : IDL.Record({ 'source' : ActionError }),
    'Mimic' : IDL.Record({ 'source' : Error_10 }),
    'Manifest' : IDL.Record({ 'source' : ManifestError }),
    'Service' : IDL.Record({ 'source' : Error_12 }),
    'Query' : IDL.Record({ 'source' : Error_7 }),
  });
  const Result_2 = IDL.Variant({ 'Ok' : Config, 'Err' : Error_13 });
  const DeleteRequest = IDL.Record({
    'key' : IDL.Vec(IDL.Text),
    'entity' : IDL.Text,
  });
  const DeleteResponse = IDL.Record({
    'keys' : IDL.Vec(IDL.Vec(IDL.Tuple(IDL.Text, IDL.Vec(IDL.Text)))),
  });
  const Result_3 = IDL.Variant({ 'Ok' : DeleteResponse, 'Err' : Error_10 });
  const LoadMethod = IDL.Variant({
    'All' : IDL.Null,
    'One' : IDL.Vec(IDL.Text),
    'Many' : IDL.Vec(IDL.Vec(IDL.Text)),
    'Range' : IDL.Tuple(IDL.Vec(IDL.Text), IDL.Vec(IDL.Text)),
    'Prefix' : IDL.Vec(IDL.Text),
  });
  const SortDirection = IDL.Variant({ 'Asc' : IDL.Null, 'Desc' : IDL.Null });
  const Filter = IDL.Variant({
    'All' : IDL.Text,
    'Fields' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text)),
  });
  const LoadFormat = IDL.Variant({ 'Rows' : IDL.Null, 'Count' : IDL.Null });
  const LoadRequest = IDL.Record({
    'entity' : IDL.Text,
    'method' : LoadMethod,
    'order' : IDL.Opt(IDL.Vec(IDL.Tuple(IDL.Text, SortDirection))),
    'offset' : IDL.Nat32,
    'limit' : IDL.Opt(IDL.Nat32),
    'filter' : IDL.Opt(Filter),
    'format' : LoadFormat,
  });
  const Metadata = IDL.Record({
    'created' : IDL.Nat64,
    'modified' : IDL.Nat64,
  });
  const QueryValue = IDL.Record({
    'metadata' : Metadata,
    'data' : IDL.Vec(IDL.Nat8),
  });
  const QueryRow = IDL.Record({
    'key' : IDL.Vec(IDL.Tuple(IDL.Text, IDL.Vec(IDL.Text))),
    'value' : QueryValue,
  });
  const LoadResponse = IDL.Variant({
    'Rows' : IDL.Vec(QueryRow),
    'Count' : IDL.Nat32,
  });
  const Result_4 = IDL.Variant({ 'Ok' : LoadResponse, 'Err' : Error_10 });
  const SaveRequestAction = IDL.Variant({
    'Create' : IDL.Null,
    'Update' : IDL.Null,
  });
  const SaveRequest = IDL.Record({
    'entity' : IDL.Text,
    'action' : SaveRequestAction,
    'data' : IDL.Vec(IDL.Nat8),
  });
  const CreateResponse = IDL.Record({ 'row' : QueryRow });
  const SaveResponse = IDL.Variant({
    'Create' : CreateResponse,
    'Update' : CreateResponse,
  });
  const Result_5 = IDL.Variant({ 'Ok' : SaveResponse, 'Err' : Error_10 });
  const Result_6 = IDL.Variant({ 'Ok' : IDL.Null, 'Err' : Error_13 });
  const Result_7 = IDL.Variant({ 'Ok' : IDL.Vec(IDL.Text), 'Err' : Error_10 });
  return IDL.Service({
    'app_state' : IDL.Func([], [AppState], ['query']),
    'app_state_cascade' : IDL.Func([AppState], [Result], []),
    'canister_balance' : IDL.Func([], [IDL.Nat], ['query']),
    'canister_caller' : IDL.Func([], [IDL.Principal], ['query']),
    'canister_id' : IDL.Func([], [IDL.Principal], ['query']),
    'canister_info' : IDL.Func([], [CanisterInfoResponse], []),
    'canister_path' : IDL.Func([], [Result_1], ['query']),
    'canister_state' : IDL.Func([], [CanisterState], ['query']),
    'canister_time' : IDL.Func([], [IDL.Nat64], ['query']),
    'canister_upgrade_children' : IDL.Func(
        [IDL.Opt(IDL.Principal)],
        [Result],
        [],
      ),
    'canister_version' : IDL.Func([], [IDL.Nat64], ['query']),
    'child_index' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Text))],
        ['query'],
      ),
    'config' : IDL.Func([], [Result_2], ['query']),
    'crud_delete' : IDL.Func([DeleteRequest], [Result_3], []),
    'crud_load' : IDL.Func([LoadRequest], [Result_4], ['composite_query']),
    'crud_save' : IDL.Func([SaveRequest], [Result_5], []),
    'fixtures_replace_all' : IDL.Func([], [Result], []),
    'ic_cycles_accept' : IDL.Func([IDL.Nat64], [IDL.Nat64], []),
    'init_async' : IDL.Func([], [Result_6], []),
    'store_keys' : IDL.Func([IDL.Text], [Result_7], ['composite_query']),
    'subnet_index' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(IDL.Text, IDL.Principal))],
        ['query'],
      ),
    'subnet_index_cascade' : IDL.Func(
        [IDL.Vec(IDL.Tuple(IDL.Text, IDL.Principal))],
        [Result],
        [],
      ),
  });
};
export const init = ({ IDL }) => { return []; };
